import styled from "styled-components"
import { compose, space, grid, layout, flexbox, color } from "styled-system"

const pageTemplate = [
  "[full-start hero-start] 2.4rem [scroll-indicator-start main-start intro-start text-start inqury-form-start bio-image-start inqury-info-start bio-text-start legal-start] repeat(4, 1fr) [legal-end inqury-form-end bio-image-end inqury-info-end text-end intro-end bio-text-end main-end scroll-indicator-end] 2.4rem [hero-end full-end]",
  "[full-start hero-start main-start] 1fr [scroll-indicator-start  intro-start text-start inqury-form-start bio-image-start inqury-info-start bio-text-start legal-start] repeat(6, 1fr) [inqury-form-end inqury-info-end bio-text-end bio-image-end  legal-end] repeat(2, 1fr) [scroll-indicator-end text-end intro-end] 1fr [main-end hero-end full-end]",
  "[full-start hero-start] 1fr [main-start scroll-indicator-start] 1fr [intro-start text-start inqury-form-start bio-image-start] repeat(2, 1fr) [legal-start] repeat(2, 1fr) [inqury-form-end] 1fr [bio-image-end inqury-info-start bio-text-start] repeat(3, 1fr) [legal-end] repeat(2, 1fr) [inqury-info-end text-end intro-end bio-text-end] 1fr [scroll-indicator-end main-end] 1fr [hero-end full-end]",
]

export const Container = styled.div(
  compose(grid, space, layout, flexbox, color),
  {
    position: "relative",
    display: "grid",
    width: "100%",
    zIndex: 1,
  }
)

Container.defaultProps = {
  bg: "white",
  gridTemplateColumns: pageTemplate,
  gridColumnGap: ["0", "2.4rem", "3.2rem"],
}
