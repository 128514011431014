import React, { useContext } from "react"
import { motion } from "framer-motion"
import ApplicationContext from "../utils/application-context"

export default function PageWrapper({ name, children }) {
  const [applicationState] = useContext(ApplicationContext)

  const pageTransition = {
    visible: {
      opacity: 1,
      transition: {
        delay: applicationState.preventTransition ? 0 : 0.5,
        duration: 0,
      },
    },
    hidden: {
      opacity: 0,
    },
    exit: {
      opacity: 1,
      transition: { duration: applicationState.preventTransition ? 0 : 0.5 },
    },
  }
  return (
    <motion.div
      key={name}
      initial={false}
      animate="visible"
      exit="exit"
      variants={pageTransition}
      style={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  )
}
