import styled from "styled-components"
import {
  compose,
  position,
  space,
  grid,
  layout,
  flexbox,
  color,
} from "styled-system"

export const Container = styled.div(
  compose(space, grid, layout, flexbox, position, color)
)

Container.defaultProps = {}
